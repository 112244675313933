import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import GroupItem from '../components/group_item';
import { readMatchGroupsActive } from '../services/match_group';
import { ButtonImgClick, ImgClick } from '../styles/global';

const ITEM_DISPLAY_SIZE = 9;

function Competition({ history, match }) {
  const [data, setData] = useState({
    items: null,
    pageIndex: null,
    pageTotal: null,
  });
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isBack = query.get('back', false);
  console.debug('Competition', data);
  const pageIndex = data.pageIndex;
  const pageTotal = data.pageTotal;
  const items = data.items
    ? data.items.slice(
        pageIndex * ITEM_DISPLAY_SIZE,
        pageIndex * ITEM_DISPLAY_SIZE + ITEM_DISPLAY_SIZE
      )
    : null;

  const nextPage = () => {
    if (pageIndex < pageTotal) {
      setData({ ...data, pageIndex: pageIndex + 1 });
    }
  };

  const previousPage = () => {
    if (pageIndex > 0) {
      setData({ ...data, pageIndex: pageIndex - 1 });
    }
  };

  useEffect(() => {
    readMatchGroupsActive().then((matchGroupsResponse) => {
      console.debug('readMatchGroupsActive', matchGroupsResponse);
      if (matchGroupsResponse && matchGroupsResponse.length > 0) {
        const pageTotal = Math.ceil(
          matchGroupsResponse.length / ITEM_DISPLAY_SIZE
        );
        const pageIndex = 0;
        setData({ ...data, items: matchGroupsResponse, pageTotal, pageIndex });
      } else {
        setData({ ...data, items: null, pageTotal: null, pageIndex: null });
      }
    });
  }, []);

  const clickItem = (id) => {
    console.debug('clickItem', id);
    history.push(`/score/${id}`);
  };

  const clickLastSeason = () => {
    console.debug('clickLastSeason');
    history.push('/last-season');
  };

  const clickScoreTable = () => {
    console.debug('clickScoreTable');
    history.push('/score-table');
  };

  return (
    <>
      <BgLeft
        src={require('../assets/images/competition_bg_side.jpg').default}
      ></BgLeft>
      <BgRight
        src={require('../assets/images/competition_bg_side.jpg').default}
      ></BgRight>
      <Backgroud
        src={require('../assets/images/competition_bg.jpg').default}
      ></Backgroud>

      <BgBarLeft
        src={require('../assets/images/competition_bar_left.png').default}
      ></BgBarLeft>
      <BgBarRight
        src={require('../assets/images/competition_bar_right.png').default}
      ></BgBarRight>
      <BgHeader
        src={require('../assets/images/competition_bg_header.png').default}
      ></BgHeader>
      <Title
        src={require('../assets/images/competition_title.png').default}
      ></Title>
      <LastSeason
        onClick={() => clickLastSeason()}
        src={require('../assets/images/competition_last.png').default}
      ></LastSeason>
      <Footer
        src={require('../assets/images/competition_bg_footer.png').default}
      ></Footer>
      <Score
        onClick={() => clickScoreTable()}
        src={require('../assets/images/competition_score.png').default}
      ></Score>
      <UpArrow
        disabled={pageIndex === null || pageIndex === 0}
        onClick={previousPage}
      ></UpArrow>
      <DownArrow
        disabled={pageIndex === null || pageIndex + 1 === pageTotal}
        onClick={nextPage}
      ></DownArrow>
      <ScrollArea>
        {items
          ? items.map((v) => (
              <GroupItem
                onClick={() => clickItem(v.id)}
                // className="ButtonClick"
                key={v.id}
                style={{
                  width: 402,
                  height: 273,
                  margin: 8,
                  flex: '0 0 auto',
                }}
                item={v}
              ></GroupItem>
            ))
          : ''}
      </ScrollArea>
      {isBack && (
        <BackImgButtom
          onClick={() => history.goBack()}
          src={require('../assets/images/home.png').default}
        />
      )}
    </>
  );
}

const BgLeft = styled.img`
  top: 0px;
  left: 0px;
  width: 611px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const BgRight = styled.img`
  top: 0px;
  left: 1309px;
  width: 611px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const Backgroud = styled.img`
  top: 0px;
  left: 270px;
  width: 1404px;
  height: 1080px;
  position: absolute;
  object-fit: stretch;
`;

const BgHeader = styled.img`
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 120px;
  position: absolute;
  object-fit: contain;
`;

const Title = styled.img`
  top: 8px;
  left: 562px;
  width: 797px;
  height: 104px;
  position: absolute;
  object-fit: contain;
`;

const BgBarLeft = styled.img`
  top: 0px;
  left: 227px;
  width: 59px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const BgBarRight = styled.img`
  top: 0px;
  left: 1657px;
  width: 59px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const LastSeason = styled(ImgClick)`
  top: 822px;
  left: 47px;
  width: 157px;
  height: 156px;
  position: absolute;
  object-fit: contain;
`;

const Footer = styled.img`
  top: 1030px;
  left: 0px;
  width: 1920px;
  height: 50px;
  position: absolute;
  object-fit: contain;
`;

const Score = styled(ImgClick)`
  top: 822px;
  left: 1730px;
  width: 170px;
  height: 154px;
  position: absolute;
  object-fit: contain;
`;

const UpArrow = styled(ButtonImgClick)`
  top: 144px;
  left: 250px;
  width: 72px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/competition_up_arrow.png')
    .default});
`;

const DownArrow = styled(ButtonImgClick)`
  top: 943px;
  left: 250px;
  width: 71px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/competition_down_arrow.png')
    .default});
`;

const ScrollArea = styled.div`
  /* overflow-y: scroll; */
  /* overflow-y: hidden; */
  top: 120px;
  left: 344px;
  width: 1297px;
  height: 910px;
  position: absolute;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  align-content: flex-start;
`;

const BackImgButtom = styled(ImgClick)`
  top: 150px;
  left: 40px;
  position: absolute;  
`;

export default Competition;
