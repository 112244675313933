import styled from 'styled-components';

export const ImgClick = styled.img`
  &:hover {
    filter: ${(props) =>
      props.disable == true ? '' : 'drop-shadow(0px 4px 4px black)'};
    transform: ${(props) => (props.disable == true ? '' : 'scale(1.06);')};
  }
  filter: ${(props) => (props.disable == true ? 'opacity(30%)' : '')};
  visibility: ${(props) => (props.hidden == true ? 'hidden' : 'visible')};

  /* filter: drop-shadow(0px 4px 4px black);
    transform: scale(1.06);
  } */
`;

export const DivClick = styled.div`
  &:hover {
    filter: drop-shadow(0px 4px 4px black);
    transform: scale(1.06);
  }
`;

export const ButtonImgClick = styled.button`
  &:hover {
    filter: ${(props) =>
      props.disabled == true ? '' : 'drop-shadow(0px 4px 4px black)'};
    transform: ${(props) => (props.disabled == true ? '' : 'scale(1.06);')};
  }
  filter: ${(props) => (props.disabled == true ? 'opacity(30%)' : '')};
  visibility: ${(props) => (props.hidden == true ? 'hidden' : 'visible')};  
  padding: 0;  
  border: none;
  background-color: transparent;
  background-size: 100% 100%;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
`;

export const InputImgBackgroud = styled.input`
  &:hover {
    filter: ${(props) =>
      props.disabled == true ? '' : 'drop-shadow(0px 4px 4px black)'};
    transform: ${(props) => (props.disabled == true ? '' : 'scale(1.06);')};
  }
  filter: ${(props) => (props.disabled == true ? 'opacity(30%)' : '')};
  visibility: ${(props) => (props.hidden == true ? 'hidden' : 'visible')};  
  padding: 0;  
  border: none;
  background-color: transparent;
  background-size: 100% 100%;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
`;