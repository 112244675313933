import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import HallOfFameItem from '../components/HallOfFameItem';
import { ButtonImgClick, ImgClick } from '../styles/global';

import { readHallOfFame } from '../services/match_group';

const ITEM_DISPLAY_SIZE = 5;

function HallOfFame({ history, match }) {
  const groupId = match.params.groupId;
  const [data, setData] = useState({});
  console.debug('HallOfFame', data);
  const pageIndex = data.pageIndex;
  const pageTotal = data.pageTotal;

  // const items = [];
  // for (let i = 0; i < 7; i++) {
  //   items.push(
  //     <HallOfFameItem
  //       style={{
  //         height: 187,
  //         // width: 1354,
  //         width: 1374,
  //         flex: '0 0 auto',
  //       }}
  //     ></HallOfFameItem>
  //   );
  // }

  const items = data.items
    ? data.items
        .slice(
          pageIndex * ITEM_DISPLAY_SIZE,
          pageIndex * ITEM_DISPLAY_SIZE + ITEM_DISPLAY_SIZE
        )
        .map((v, index) => (
          <HallOfFameItem
            key={pageIndex * ITEM_DISPLAY_SIZE + index + 1}
            style={{
              height: 187,
              // width: 1354,
              width: 1374,
              flex: '0 0 auto',
            }}
            no={pageIndex * ITEM_DISPLAY_SIZE + index + 1}
            data={v}
          ></HallOfFameItem>
        ))
    : '';

  const nextPage = () => {
    if (pageIndex < pageTotal) {
      setData({ ...data, pageIndex: pageIndex + 1 });
    }
  };

  const previousPage = () => {
    if (pageIndex > 0) {
      setData({ ...data, pageIndex: pageIndex - 1 });
    }
  };

  const backClick = () => {
    history.goBack();
  };

  useEffect(() => {
    readHallOfFame().then((v) => {
      console.debug('readHallOfFame', v);
      const r = {};
      if (v && v.length > 0) {
        r.pageTotal = Math.ceil(v.length / ITEM_DISPLAY_SIZE);
        r.pageIndex = 0;
      } else {
        r.pageTotal = null;
        r.pageIndex = null;
      }
      r.items = v;
      setData(r);
    });
  }, []);

  return (
    <>
      <BgRight
        src={require('../assets/images/hall_of_fame_bg_rigth.png').default}
      ></BgRight>
      <FilterRight
        src={require('../assets/images/hall_of_fame_filter.png').default}
      ></FilterRight>
      <BgCenter
        src={require('../assets/images/hall_of_fame_bg_center.png').default}
      ></BgCenter>
      <FilterLeft
        src={require('../assets/images/hall_of_fame_filter.png').default}
      ></FilterLeft>
      <VerticalLine1
        src={require('../assets/images/hall_of_fame_vertical_line.png').default}
      ></VerticalLine1>
      <VerticalLine2
        src={require('../assets/images/hall_of_fame_vertical_line.png').default}
      ></VerticalLine2>
      <VerticalLine3
        src={require('../assets/images/hall_of_fame_vertical_line.png').default}
      ></VerticalLine3>
      <VerticalLine4
        src={require('../assets/images/hall_of_fame_vertical_line.png').default}
      ></VerticalLine4>
      <Bar1
        src={require('../assets/images/hall_of_fame_bar_1.png').default}
      ></Bar1>
      <ScrollArea>{items}</ScrollArea>
      <Bar2
        src={require('../assets/images/hall_of_fame_bar_2.png').default}
      ></Bar2>
      <Bar3
        src={require('../assets/images/hall_of_fame_bar_3.png').default}
      ></Bar3>
      <Header
        src={require('../assets/images/hall_of_fame_header.png').default}
      ></Header>
      <Head
        src={require('../assets/images/hall_of_fame_head.png').default}
      ></Head>
      <HeaderOne
        src={require('../assets/images/hall_of_fame_header_one.png').default}
      ></HeaderOne>
      <HeaderTwo
        src={require('../assets/images/hall_of_fame_header_two.png').default}
      ></HeaderTwo>
      <HeaderThree
        src={require('../assets/images/hall_of_fame_header_three.png').default}
      ></HeaderThree>
      <Cup src={require('../assets/images/hall_of_fame_cup.png').default}></Cup>
      <Back
        onClick={() => backClick()}
        src={require('../assets/images/hall_of_fame_back.png').default}
      ></Back>
      <Up
        disabled={
          pageIndex === null || pageIndex === undefined || pageIndex === 0
        }
        onClick={previousPage}
      ></Up>
      <Down
        disabled={
          pageIndex === null ||
          pageIndex === undefined ||
          pageIndex + 1 === pageTotal
        }
        onClick={nextPage}
      ></Down>
    </>
  );
}

const BgRight = styled.img`
  top: 0px;
  left: 1309px;
  width: 611px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const FilterRight = styled.img`
  top: 131px;
  left: 1336px;
  width: 584px;
  height: 949px;
  position: absolute;
  object-fit: contain;
`;

const BgCenter = styled.img`
  top: 0px;
  left: 0px;
  width: 1448px;
  height: 1080px;
  position: absolute;
  object-fit: stretch;
`;

const FilterLeft = styled.img`
  top: 131px;
  left: 40px;
  width: 584px;
  height: 949px;
  position: absolute;
  object-fit: contain;
`;

const VerticalLine1 = styled.img`
  top: 0px;
  left: 669px;
  width: 13px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const VerticalLine2 = styled.img`
  top: 0px;
  left: 885px;
  width: 13px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const VerticalLine3 = styled.img`
  top: 0px;
  left: 1137px;
  width: 13px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const VerticalLine4 = styled.img`
  top: 0px;
  left: 1369px;
  width: 13px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const Bar1 = styled.img`
  top: 0px;
  left: 0px;
  width: 116px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const ScrollArea = styled.div`
  /* overflow-y: scroll; */
  top: 140px;
  left: 0px;
  width: 1374px;
  height: 940px;
  position: absolute;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  align-content: flex-start;
`;

const Bar2 = styled.img`
  top: 0px;
  left: 553px;
  width: 122px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const Bar3 = styled.img`
  top: 0px;
  left: 1374px;
  width: 122px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const Header = styled.img`
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 152px;
  position: absolute;
  object-fit: contain;
`;

const Head = styled.img`
  top: 9px;
  left: 0px;
  width: 633px;
  height: 148px;
  position: absolute;
  object-fit: contain;
`;

const HeaderOne = styled.img`
  top: 12px;
  left: 700px;
  width: 169px;
  height: 128px;
  position: absolute;
  object-fit: contain;
`;

const HeaderTwo = styled.img`
  top: 12px;
  left: 942px;
  width: 169px;
  height: 128px;
  position: absolute;
  object-fit: contain;
`;

const HeaderThree = styled.img`
  top: 9px;
  left: 1177px;
  width: 169px;
  height: 128px;
  position: absolute;
  object-fit: contain;
`;

const Cup = styled.img`
  top: 175px;
  left: 1496px;
  width: 387px;
  height: 713px;
  position: absolute;
  object-fit: contain;
`;

const Back = styled(ImgClick)`
  top: 957px;
  left: 1612px;
  width: 185px;
  height: 81px;
  position: absolute;
  object-fit: contain;
`;

const Up = styled(ButtonImgClick)`
  top: 175px;
  left: 542px;
  width: 72px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/hall_of_fame_up.png')
    .default});
`;

const Down = styled(ButtonImgClick)`
  top: 998px;
  left: 542px;
  width: 71px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/hall_of_fame_down.png')
    .default});
`;

export default HallOfFame;
