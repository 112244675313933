import React from 'react';
import styled from 'styled-components';
// import { DivClick } from '../styles/global';

function Fixture1StudentCurrent(props) {
  const data = props.data ? props.data : {};
  return (
    <Container {...props}>
      {data.isTeacher ? (
        <Teacher
          src={require('../assets/images/fixture1_teacher.png').default}
        ></Teacher>
      ) : (
        <Student
          src={require('../assets/images/fixture1_student.png').default}
        ></Student>
      )}
      {data.imageUrl ? (
        <StudentImage src={data.imageUrl}></StudentImage>
      ) : (
        <StudentImage
          src={require('../assets/images/student_image.jpg').default}
        ></StudentImage>
      )}
      <StudentName>{data.name}</StudentName>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
`;

const Teacher = styled.img`
  top: 9px;
  left: 8px;
  width: 164px;
  height: 164px;
  position: absolute;
  object-fit: contain;
`;

const StudentImage = styled.img`
  top: 21px;
  left: 18px;
  width: 144px;
  height: 144px;
  position: absolute;
  object-fit: contain;
  border-radius: 50%;
`;

const Student = styled.img`
  top: 9px;
  left: 6px;
  width: 168px;
  height: 168px;
  position: absolute;
  object-fit: contain;
`;

const StudentName = styled.span`
  font-family: PSLxText;
  top: 139px;
  left: 0px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: white;
  height: 54px;
  width: 168px;
  font-size: 40px;
  text-align: center;
  -webkit-text-stroke: 1px black;
`;

export default Fixture1StudentCurrent;
