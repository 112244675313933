import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Fixture1Student from '../components/Fixture1Student';
import Fixture1StudentCurrent from '../components/Fixture1StudentCurrent';
import Fixture1StudentNo from '../components/Fixture1StudentNo';
import { ImgClick, ButtonImgClick, InputImgBackgroud } from '../styles/global';

import {
  readMatchGroupStudentScore,
  readMatches,
  readMatchGroupsActive,
  editMatcheResults,
} from '../services/match_group';
import { handleError, handleSuccess } from '../services/handleResponse';

import { confirmMove } from '../components/messageUtil';

const ITEM_DISPLAY_SIZE = 24;

function Fixture1({ history, match }) {
  const { matchGroupId } = match.params;
  const [data, setData] = useState({});
  const [formData, setFormData] = useState({});
  const [isPass, setIsPass] = useState(false);
  const matchGroups = data.matchGroups;
  const studentScores = data.studentScores;
  const matches = data.matches;
  const roundItems = data.roundItems;
  const teachers = data.teachers;
  // const competitors = data.competitors;
  const studentScoreIndex = data.studentScoreIndex;
  const roundIndex = data.roundIndex;
  const matchGroupIndex = data.matchGroupIndex;
  const studentScore = studentScores ? studentScores[studentScoreIndex] : null;
  // console.debug('studentScore', studentScore);
  const matchGroupIndexTemp = matchGroupIndex ? matchGroupIndex : 0;
  const matchGroup = matchGroups ? matchGroups[matchGroupIndexTemp] : null;
  const updateData = data.updateData ? data.updateData : {};
  const pageIndex = data.pageIndex;
  const pageTotal = data.pageTotal;
  const competitors = data.competitors
    ? data.competitors.slice(
      pageIndex * ITEM_DISPLAY_SIZE,
      pageIndex * ITEM_DISPLAY_SIZE + ITEM_DISPLAY_SIZE
    )
    : null;

  const onInputChange = (e) => {
    const temp = { ...formData, [e.target.name]: e.target.value };
    console.debug('onChange', temp);
    setFormData(temp);
  };

  const nextPage = () => {
    if (pageIndex < pageTotal) {
      setData({ ...data, pageIndex: pageIndex + 1 });
    }
  };

  const previousPage = () => {
    if (pageIndex > 0) {
      setData({ ...data, pageIndex: pageIndex - 1 });
    }
  };

  console.debug('Fixture1:data', data);

  const setCompotitorData = (matchList, round, studentId) => {
    const teachersTemp = [];
    const competitorsTemp = [];
    for (let match of matchList) {
      if (match.round == round) {
        const index = match.students.findIndex((v) => v.id == studentId);
        if (index === -1) {
          continue;
        }
        if (match.students[index ^ 1].isTeacher) {
          teachersTemp.push({
            matchId: match.id,
            competitor: match.students[index ^ 1],
          });
        } else {
          competitorsTemp.push({
            matchId: match.id,
            competitor: match.students[index ^ 1],
          });
        }
      }
    }
    data.teachers = teachersTemp;
    data.competitors = competitorsTemp;
    if (competitorsTemp && competitorsTemp.length > 0) {
      data.pageTotal = Math.ceil(competitorsTemp.length / ITEM_DISPLAY_SIZE);
      data.pageIndex = data.pageIndex ? data.pageIndex : 0;
    } else {
      data.pageTotal = null;
      data.pageIndex = null;
    }
  };

  const readMatchDetail = async (matchGroupParam, studentIndex) => {
    const allOfStudent = await readMatchGroupStudentScore(
      matchGroupParam.id
    );
    console.debug('readMatchGroupStudentScore', allOfStudent);
    const studentScoresResponse = allOfStudent.filter(v => v.isTeacher === false);
    if (studentScoresResponse === null) {
      return;
    }
    // let studentIndex = studentScoreIndex;
    if (studentIndex === undefined) {
      studentIndex = 0;
    }

    const studentId = studentScoresResponse[studentIndex].studentId;
    const matchesTemp = await readMatches(matchGroupParam.id);
    const roundItemsTemp = [];
    for (let d of matchesTemp) {
      if (!roundItemsTemp.find((v) => v == d.round)) {
        roundItemsTemp.push(d.round);
      }
    }
    let roundI = roundIndex;
    if (roundI === undefined) {
      roundI = 0;
    }
    setCompotitorData(matchesTemp, roundItemsTemp[roundI], studentId);
    data.studentScores = studentScoresResponse;
    data.studentScoreIndex = studentIndex;
    data.matches = matchesTemp;
    data.roundIndex = roundI;
    data.roundItems = roundItemsTemp;
    console.debug('readMatchDetail', data);
  };

  useEffect(() => {
    const func = async () => {
      const matchGroupsResponse = await readMatchGroupsActive();
      if (matchGroupsResponse === null) {
        return;
      }
      data.matchGroups = matchGroupsResponse;
      if (matchGroupId !== undefined) {
        const found = matchGroupsResponse.findIndex(
          (v) => v.id == matchGroupId
        );
        // console.debug('found', found);
        if (found !== -1) {
          data.matchGroupIndex = found;
          await readMatchDetail(matchGroupsResponse[found], 0);
        }
      } else if (matchGroupsResponse.length > 0) {
        data.matchGroupIndex = 0;
        await readMatchDetail(matchGroupsResponse[0], 0);
      }
      setData({ ...data });
      console.debug('useEffect', data);
    };
    func();
  }, []);

  const canChangePage = async () => {
    if (updateData && Object.keys(updateData).length > 0) {
      const result = await confirmMove();
      console.debug('confirmMove', result);
      if (result.isConfirmed == false) {
        return false;
      }
    }
    return true;
  };

  const studentNext = async () => {
    if (!await canChangePage()) return;
    if (studentScoreIndex < studentScores.length - 1) {
      const studentId = studentScores[studentScoreIndex + 1].studentId;
      setCompotitorData(matches, roundItems[roundIndex], studentId);
      setData({
        ...data,
        updateData: {},
        studentScoreIndex: studentScoreIndex + 1,
      });
    }
    console.debug('studentNext');
  };

  const studentPrevious = async () => {
    if (!await canChangePage()) return;
    if (studentScoreIndex > 0) {
      const studentId = studentScores[studentScoreIndex - 1].studentId;
      setCompotitorData(matches, roundItems[roundIndex], studentId);
      setData({
        ...data,
        updateData: {},
        studentScoreIndex: studentScoreIndex - 1,
      });
    }
    console.debug('studentPrevious');
  };

  const roundNext = async () => {
    if (!await canChangePage()) return;
    if (roundItems && roundIndex < roundItems.length - 1) {
      const studentId = studentScores[studentScoreIndex].studentId;
      setCompotitorData(matches, roundItems[roundIndex + 1], studentId);
      setData({ ...data, updateData: {}, roundIndex: roundIndex + 1 });
    }
    console.debug('roundNext');
  };

  const roundPreivous = async () => {
    if (!await canChangePage()) return;
    if (roundItems && roundIndex > 0) {
      const studentId = studentScores[studentScoreIndex].studentId;
      setCompotitorData(matches, roundItems[roundIndex - 1], studentId);
      setData({ ...data, updateData: {}, roundIndex: roundIndex - 1 });
    }
    console.debug('roundPreivous');
  };

  const matchGroupNext = async () => {
    if (!await canChangePage()) return;
    if (matchGroups && matchGroupIndex < matchGroups.length - 1) {
      await readMatchDetail(matchGroups[matchGroupIndex + 1], 0);
      setData({
        ...data,
        updateData: {},
        matchGroupIndex: matchGroupIndex + 1,
      });
    }
    console.debug('matchGroupNext', data);
  };

  const matchGroupPrevious = async () => {
    if (!await canChangePage()) return;
    if (matchGroups && matchGroupIndex > 0) {
      await readMatchDetail(matchGroups[matchGroupIndex - 1], 0);
      setData({
        ...data,
        updateData: {},
        matchGroupIndex: matchGroupIndex - 1,
      });
    }
    console.debug('matchGroupPrevious', data);
  };

  const teacherTags = teachers
    ? teachers.map((v, index) => (
      <Fixture1Student
        triggleResult={() => triggleResult(v.competitor)}
        update={updateData[v.matchId]}
        data={v.competitor}
        key={index + 1}
        data={v.competitor}
        style={{
          height: 190,
          width: 180,
          marginRight: 10,
          marginLeft: 10,
        }}
      ></Fixture1Student>
    ))
    : '';

  const RESULT_VALUE = {
    0: null,
    1: false,
    2: true,
    null: 0,
    false: 1,
    true: 2,
  };

  const resultNext = (value) => {
    return RESULT_VALUE[(RESULT_VALUE[value] + 1) % 3];
  };

  const triggleResult = (competitor) => {
    // console.debug('triggleResult', competitor);
    console.debug('triggleResult.matchId', competitor.matchResult.matchId);
    const matchId = competitor.matchResult.matchId;
    const oldIsWin = competitor.matchResult.isWin;
    let newIsWin;
    console.debug('triggleResult.isWin', competitor.matchResult.isWin);
    console.debug('triggleResult.studentId', competitor.matchResult.studentId);

    if (updateData[matchId] !== undefined) {
      newIsWin = resultNext(updateData[matchId].isWin);
      if (updateData[matchId].originalIsWin === newIsWin) {
        delete updateData[matchId];
      } else {
        updateData[matchId].isWin = newIsWin;
      }
    } else {
      newIsWin = resultNext(oldIsWin);
      updateData[matchId] = {
        isWin: newIsWin,
        studentId: competitor.id,
        originalIsWin: oldIsWin,
      };
    }

    console.debug('updateData', updateData);
    // setUpdateData({ ...updateData });
    setData({ ...data, updateData });
  };

  const CompetitorTags = competitors
    ? competitors.map((v, index) => (
      <Fixture1StudentNo
        triggleResult={() => triggleResult(v.competitor)}
        key={pageIndex * ITEM_DISPLAY_SIZE + index + 1}
        no={pageIndex * ITEM_DISPLAY_SIZE + index + 1}
        update={updateData[v.matchId]}
        data={v.competitor}
        style={{
          // height: 228,
          height: 250,
          width: 200,
        }}
      ></Fixture1StudentNo>
    ))
    : '';

  const getWinScore = () => {
    // console.debug('getWinScore.studentScore', studentScore);
    // console.debug('getWinScore.updateData', updateData);
    let win = studentScore ? studentScore.win : 0;
    for (let v in updateData) {
      if (updateData[v].isWin === false) {
        win += 1;
      } else {
        if (updateData[v].originalIsWin === false) {
          win += -1;
        }
      }
    }
    // console.debug('getWinScore.win', win);
    return win > 0 ? win : 0;
  };

  const getLoseScore = () => {
    let lose = studentScore ? studentScore.lose : 0;
    for (let v in updateData) {
      if (updateData[v].isWin) {
        lose += 1;
      } else {
        if (updateData[v].originalIsWin) {
          lose += -1;
        }
      }
    }
    return lose > 0 ? lose : 0;
  };

  const save = async () => {
    console.debug('save', updateData);
    const results = [];
    for (let matchId in updateData) {
      results.push({
        matchId,
        isWin: updateData[matchId].isWin,
        studentId: updateData[matchId].studentId,
      });
    }
    try {
      // const responseData = await editMatcheResults(formData.password, results);
      const responseData = await editMatcheResults('password', results);
      if (responseData !== null) {
        handleSuccess();
        await readMatchDetail(matchGroup, studentScoreIndex);
        setData({ ...data, updateData: {} });
      }
    } catch (err) {
      console.debug('editMatcheResults', err);
      handleError(err);
    }
  };

  const goBack = async () => {
    if (!await canChangePage()) return;
    history.goBack()
  }

  return (
    <>
      <Background
        src={require('../assets/images/fixture1_background.jpg').default}
      ></Background>
      <TeacherList>{teacherTags}</TeacherList>
      <VerticalBar
        src={require('../assets/images/fixture1_verical_line.png').default}
      ></VerticalBar>
      <VerticalBar1
        src={require('../assets/images/fixture1_verical_line.png').default}
      ></VerticalBar1>
      <VerticalBar2
        src={require('../assets/images/fixture1_verical_line.png').default}
      ></VerticalBar2>
      <VerticalBar3
        src={require('../assets/images/fixture1_verical_line.png').default}
      ></VerticalBar3>
      <VerticalBar4
        src={require('../assets/images/fixture1_verical_line.png').default}
      ></VerticalBar4>
      <ScrollArea>{CompetitorTags}</ScrollArea>
      <VerticalBar5
        src={require('../assets/images/fixture1_verical_line.png').default}
      ></VerticalBar5>
      <VerticalBarShorter
        src={require('../assets/images/fixture1_verical_line.png').default}
      ></VerticalBarShorter>
      <VerticalBarShorter2
        src={require('../assets/images/fixture1_verical_line.png').default}
      ></VerticalBarShorter2>
      <Header
        src={require('../assets/images/fixture1_header.png').default}
      ></Header>
      <NameLeft
        src={require('../assets/images/fixture1_footer.png').default}
      ></NameLeft>
      <GroupPrevious
        onClick={() => matchGroupPrevious()}
        disabled={
          matchGroupIndex === null ||
          matchGroupIndex === undefined ||
          matchGroupIndex <= 0
        }
      ></GroupPrevious>
      <GroupNext
        onClick={() => matchGroupNext()}
        disabled={
          matchGroupIndex === null ||
          matchGroupIndex === undefined ||
          matchGroupIndex >= matchGroups.length - 1
        }
      ></GroupNext>
      <div onClick={() => { setIsPass(true) }}>
        <GroupName>{matchGroup ? matchGroup.name : ''}</GroupName>
      </div>
      <RoundName>
        {roundItems ? `ROUND ${roundItems[roundIndex]}` : ''}
      </RoundName>
      <RoundPrevious
        onClick={() => roundPreivous()}
        disabled={
          roundIndex === null || roundIndex === undefined || roundIndex <= 0
        }
      ></RoundPrevious>
      <RoundNext
        onClick={() => roundNext()}
        disabled={
          roundIndex === null ||
          roundIndex === undefined ||
          roundIndex >= roundItems.length - 1
        }
      ></RoundNext>
      <Fixture1StudentCurrent
        style={{
          position: 'absolute',
          top: 69,
          left: 28,
          height: 190,
          width: 180,
        }}
        data={studentScore}
      ></Fixture1StudentCurrent>
      <StudentPrevious
        onClick={() => studentPrevious()}
        disabled={
          studentScoreIndex === null ||
          studentScoreIndex === undefined ||
          studentScoreIndex <= 0
        }
      ></StudentPrevious>
      <StudentNext
        onClick={() => studentNext()}
        disabled={
          studentScoreIndex === null ||
          studentScoreIndex === undefined ||
          studentScoreIndex >= studentScores.length - 1
        }
      ></StudentNext>
      <LebelWin
        src={require('../assets/images/fixture1_win.png').default}
      ></LebelWin>
      <LebalLose
        src={require('../assets/images/fixture1_lose.png').default}
      ></LebalLose>
      <BoxLose
        src={require('../assets/images/fixture1_boxlose.png').default}
      ></BoxLose>
      <BoxWin
        src={require('../assets/images/fixture1_boxwin.png').default}
      ></BoxWin>
      <Back
        onClick={() => goBack()}
        src={require('../assets/images/fixture1_back.png').default}
      ></Back>
      <LineRed
        src={
          require('../assets/images/fixture1_horizontal_line_red.png').default
        }
      ></LineRed>
      <Line1
        src={require('../assets/images/fixture1_horizontal_line.png').default}
      ></Line1>
      <Line
        src={require('../assets/images/fixture1_horizontal_line.png').default}
      ></Line>
      <Line2
        src={require('../assets/images/fixture1_horizontal_line.png').default}
      ></Line2>
      <VerticalBarLeft
        src={require('../assets/images/fixture1_vertical_bar.png').default}
      ></VerticalBarLeft>
      <VerticalBarRight
        src={require('../assets/images/fixture1_vertical_bar.png').default}
      ></VerticalBarRight>
      <ItemsUp
        disabled={pageIndex === null || pageIndex === 0}
        onClick={previousPage}
      ></ItemsUp>
      <ItemDown
        disabled={pageIndex === null || pageIndex + 1 === pageTotal}
        onClick={nextPage}
      ></ItemDown>
      {/* <Ok
        onClick={() => {
          save();
        }}
        disable={updateData && Object.keys(updateData).length === 0}
        src={require('../assets/images/fixture1_ok.png').default}
      ></Ok> */}
      <Ok
        onClick={() => {
          if (isPass) {
            setIsPass(false);
            save();
          }
        }}
        disabled={updateData && Object.keys(updateData).length === 0}
      >
        {/* <img src={require('../assets/images/fixture1_ok.png').default} /> */}
      </Ok>

      <Password
        name="password"
        // onChange={onInputChange}
        type="password"
        disabled="true"
      ></Password>
      <LabelPassword
        src={require('../assets/images/fixture1_label_password.png').default}
      ></LabelPassword>
      <Win>{getWinScore()}</Win>
      <Lose>{getLoseScore()}</Lose>
    </>
  );
}

const Background = styled.img`
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const TeacherList = styled.div`
  top: 69px;
  left: 266px;
  width: 1200px;
  height: 194px;
  position: absolute;
  flex-direction: row;
  display: flex;
`;

const VerticalBar = styled.img`
  top: -7px;
  left: 463px;
  width: 8px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const VerticalBar1 = styled.img`
  top: 0px;
  left: 862px;
  width: 8px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const VerticalBar2 = styled.img`
  top: 0px;
  left: 1061px;
  width: 8px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const VerticalBar3 = styled.img`
  top: 0px;
  left: 661px;
  width: 8px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const VerticalBar4 = styled.img`
  top: 0px;
  left: 1260px;
  width: 8px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const ScrollArea = styled.div`
  /* overflow-y: hidden; */
  top: 288px;
  left: 266px;
  width: 1612px;
  height: 738px;
  position: absolute;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  align-content: flex-start;
`;

const VerticalBar5 = styled.img`
  top: -7px;
  left: 1462px;
  width: 8px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const VerticalBarShorter = styled.img`
  top: 281px;
  left: 1662px;
  width: 8px;
  height: 792px;
  position: absolute;
  object-fit: contain;
`;

const VerticalBarShorter2 = styled.img`
  top: 281px;
  left: 1860px;
  width: 8px;
  height: 792px;
  position: absolute;
  object-fit: contain;
`;

const Header = styled.img`
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 72px;
  position: absolute;
  object-fit: contain;
`;

const NameLeft = styled.img`
  top: 1026px;
  left: 0px;
  width: 1920px;
  height: 54px;
  position: absolute;
  object-fit: contain;
`;

const GroupPrevious = styled(ButtonImgClick)`
  top: 0px;
  left: 664px;
  width: 71px;
  height: 72px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture1_red_left_arrow.png')
    .default});
`;

const GroupNext = styled(ButtonImgClick)`
  top: 0px;
  left: 1182px;
  width: 71px;
  height: 72px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture1_red_right_arrow.png')
    .default});
`;

const GroupName = styled.span`
  font-family: PSLxText;
  top: 0px;
  left: 775px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  height: 84px;
  width: 370px;
  font-size: 50px;
  text-align: center;
  -webkit-text-stroke: 1px white;
`;

const RoundName = styled.span`
  font-family: PSLxText;
  top: 4px;
  left: 1526px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  height: 67px;
  width: 266px;
  font-size: 50px;
  text-align: center;
  text-shadow: 0px 3px 3px #000000;
`;

const RoundPrevious = styled(ButtonImgClick)`
  top: 0px;
  left: 1444px;
  width: 72px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture1_round_left_arrow.png')
    .default});
`;

const RoundNext = styled(ButtonImgClick)`
  top: 0px;
  left: 1792px;
  width: 71px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture1_round_right_arrow.png')
    .default});
`;

const StudentPrevious = styled(ButtonImgClick)`
  top: 269px;
  left: 28px;
  width: 71px;
  height: 72px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture1_red_left_arrow.png')
    .default});
`;

const StudentNext = styled(ButtonImgClick)`
  top: 267px;
  left: 140px;
  width: 71px;
  height: 72px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture1_red_right_arrow.png')
    .default});
`;

const LebelWin = styled.img`
  top: 363px;
  left: 50px;
  width: 135px;
  height: 54px;
  position: absolute;
  object-fit: contain;
`;

const LebalLose = styled.img`
  top: 611px;
  left: 30px;
  width: 178px;
  height: 56px;
  position: absolute;
  object-fit: contain;
`;

const BoxLose = styled.img`
  top: 677px;
  left: 42px;
  width: 153px;
  height: 152px;
  position: absolute;
  object-fit: contain;
`;

const BoxWin = styled.img`
  top: 428px;
  left: 41px;
  width: 153px;
  height: 152px;
  position: absolute;
  object-fit: contain;
`;

const Back = styled(ImgClick)`
  top: 888px;
  left: 26px;
  width: 185px;
  height: 81px;
  position: absolute;
  object-fit: contain;
`;

const LineRed = styled.img`
  top: 267px;
  left: 235px;
  width: 1685px;
  height: 21px;
  position: absolute;
  object-fit: contain;
`;

const Line1 = styled.img`
  top: 778px;
  left: 239px;
  width: 1681px;
  height: 8px;
  position: absolute;
  object-fit: contain;
`;

const Line = styled.img`
  top: 259px;
  left: 239px;
  width: 1681px;
  height: 8px;
  position: absolute;
  object-fit: contain;
`;

const Line2 = styled.img`
  top: 532px;
  left: 239px;
  width: 1681px;
  height: 8px;
  position: absolute;
  object-fit: contain;
`;

const VerticalBarLeft = styled.img`
  top: 0px;
  left: 224px;
  width: 42px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const VerticalBarRight = styled.img`
  top: 0px;
  left: 1878px;
  width: 42px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const ItemsUp = styled(ButtonImgClick)`
  top: 270px;
  left: 1835px;
  width: 72px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture1_arrow_up.png')
    .default});
`;

const ItemDown = styled(ButtonImgClick)`
  top: 991px;
  left: 1836px;
  width: 71px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture1_arrow_down.png')
    .default});
`;

// const Ok = styled(ImgClick)`
//   top: 130px;
//   left: 1738px;
//   width: 122px;
//   height: 73px;
//   position: absolute;
//   object-fit: contain;
// `;

const Ok = styled(ButtonImgClick)`
  top: 130px;
  left: 1738px;
  width: 122px;
  height: 73px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture1_ok.png').default});
`;

const Password = styled(InputImgBackgroud)`
  font-family: PSLxText;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  top: 150px;
  left: 1511px;
  width: 197px;
  height: 46px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture1_boxpass.png')
    .default});
`;

const LabelPassword = styled.img`
  top: 108px;
  left: 1526px;
  width: 165px;
  height: 30px;
  position: absolute;
  object-fit: contain;
`;

const Win = styled.span`
  font-family: PSLxText;
  top: 456px;
  left: 66px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  height: 96px;
  width: 103px;
  font-size: 80px;
  text-align: center;
`;

const Lose = styled.span`
  font-family: PSLxText;
  top: 705px;
  left: 67px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  height: 96px;
  width: 103px;
  font-size: 80px;
  text-align: center;
`;

export default Fixture1;
