import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

// import logo from './logo.svg';
import './App.css';
import Competition from './screens/competition';
import LastSeason from './screens/last_season';
import ScoreTable from './screens/score_table';
import Score from './screens/score';
import Fixture1 from './screens/Fixture1';
import Fixture2 from './screens/Fixture2';
import HallOfFame from './screens/HallOfFame';
import Test from './screens/Test';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/competition" />
          {/* <Redirect to="/fixture2" /> */}
        </Route>
        <Route exact path="/competition" component={Competition} />
        <Route exact path="/last-season" component={LastSeason} />
        <Route exact path="/score-table" component={ScoreTable} />
        <Route exact path="/score/:matchGroupId" component={Score} />
        <Route exact path="/fixture1" component={Fixture1} />
        <Route exact path="/fixture1/:matchGroupId" component={Fixture1} />        
        <Route exact path="/fixture2" component={Fixture2} />
        <Route exact path="/fixture2/:matchGroupId" component={Fixture2} />
        <Route exact path="/hall-of-fame" component={HallOfFame} />
        <Route exact path="/hall-of-fame/:groupId" component={HallOfFame} />
        <Route exact path="/test" component={Test} />
      </Switch>
    </Router>
  );
}

export default App;

