import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import GroupItem from '../components/group_item';
import { readMatchGroupsHistory } from '../services/match_group';
import { ButtonImgClick, ImgClick } from '../styles/global';

const ITEM_DISPLAY_SIZE = 9;

function LastSeason({ history }) {
  const [data, setData] = useState({
    items: null,
    pageIndex: null,
    pageTotal: null,
  });
  console.debug('Competition', data);
  const pageIndex = data.pageIndex;
  const pageTotal = data.pageTotal;
  const items = data.items
    ? data.items.slice(
        pageIndex * ITEM_DISPLAY_SIZE,
        pageIndex * ITEM_DISPLAY_SIZE + ITEM_DISPLAY_SIZE
      )
    : null;

  const nextPage = () => {
    if (pageIndex < pageTotal) {
      setData({ ...data, pageIndex: pageIndex + 1 });
    }
  };

  const previousPage = () => {
    if (pageIndex > 0) {
      setData({ ...data, pageIndex: pageIndex - 1 });
    }
  };

  useEffect(() => {
    readMatchGroupsHistory().then((responseData) => {
      console.debug('readMatchGroupsHistory', responseData);
      if (responseData && responseData.length > 0) {
        const pageTotal = Math.ceil(
          responseData.length / ITEM_DISPLAY_SIZE
        );
        const pageIndex = 0;
        setData({ ...data, items: responseData, pageTotal, pageIndex });
      } else {
        setData({ ...data, items: null, pageTotal: null, pageIndex: null });
      }
    });
  }, []);

  const clickItem = (id) => {
    console.debug('clickItem', id);
    history.push(`/score/${id}`);
  };

  const clickBack = () => {
    console.debug('clickBack');
    history.goBack();
  };

  return (
    <>
      <BgLeft
        src={require('../assets/images/competition_bg_side.jpg').default}
      ></BgLeft>
      <BgRight
        src={require('../assets/images/competition_bg_side.jpg').default}
      ></BgRight>
      <Backgroud
        src={require('../assets/images/competition_bg.jpg').default}
      ></Backgroud>

      <BgBarLeft
        src={require('../assets/images/competition_bar_left.png').default}
      ></BgBarLeft>
      <BgBarRight
        src={require('../assets/images/competition_bar_right.png').default}
      ></BgBarRight>
      <BgHeader
        src={require('../assets/images/competition_bg_header.png').default}
      ></BgHeader>
      <Title
        src={require('../assets/images/competition_title.png').default}
      ></Title>
      <Back
        onClick={() => clickBack()}
        src={require('../assets/images/back.png').default}
      ></Back>
      <Footer
        src={require('../assets/images/competition_bg_footer.png').default}
      ></Footer>
      <UpArrow
        disabled={pageIndex === null || pageIndex === 0}
        onClick={previousPage}
      ></UpArrow>
      <DownArrow
        disabled={pageIndex === null || pageIndex + 1 === pageTotal}
        onClick={nextPage}
      ></DownArrow>
      <ScrollArea>
        {items
          ? items.map((v) => (
              <GroupItem
                onClick={() => clickItem(v.id)}
                // className="ButtonClick"
                key={v.id}
                style={{
                  width: 402,
                  height: 273,
                  margin: 8,
                  flex: '0 0 auto',
                }}
                // name={v.name}
                // imageUrl={v.imageUrl}
                item={v}
              ></GroupItem>
            ))
          : ''}
      </ScrollArea>
    </>
  );
}

const BgLeft = styled.img`
  top: 0px;
  left: 0px;
  width: 611px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const BgRight = styled.img`
  top: 0px;
  left: 1309px;
  width: 611px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const Backgroud = styled.img`
  top: 0px;
  left: 270px;
  width: 1404px;
  height: 1080px;
  position: absolute;
  object-fit: stretch;
`;

const BgHeader = styled.img`
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 120px;
  position: absolute;
  object-fit: contain;
`;

const Title = styled.img`
  top: 8px;
  left: 562px;
  width: 797px;
  height: 104px;
  position: absolute;
  object-fit: contain;
`;

const BgBarLeft = styled.img`
  top: 0px;
  left: 227px;
  width: 59px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const BgBarRight = styled.img`
  top: 0px;
  left: 1657px;
  width: 59px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const Back = styled(ImgClick)`
  top: 822px;
  left: 47px;
  width: 157px;
  height: 156px;
  position: absolute;
  object-fit: contain;
`;

const Footer = styled.img`
  top: 1030px;
  left: 0px;
  width: 1920px;
  height: 50px;
  position: absolute;
  object-fit: contain;
`;

const Score = styled(ImgClick)`
  top: 822px;
  left: 1730px;
  width: 170px;
  height: 154px;
  position: absolute;
  object-fit: contain;
`;

const UpArrow = styled(ButtonImgClick)`
  top: 144px;
  left: 250px;
  width: 72px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/competition_up_arrow.png')
    .default});
`;

const DownArrow = styled(ButtonImgClick)`
  top: 943px;
  left: 250px;
  width: 71px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/competition_down_arrow.png')
    .default});
`;

const ScrollArea = styled.div`
  /* overflow-y: scroll; */
  /* overflow-y: hidden; */
  top: 120px;
  left: 344px;
  width: 1297px;
  height: 910px;
  position: absolute;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  align-content: flex-start;
`;

export default LastSeason;
