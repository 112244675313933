const prod = {
  // hostUrl: 'https://study.tuagom.com/webservice',
  // hostUrl: 'https://match.tuagom.com/study-test/service',
  // hostUrl: 'https://study.tuagom.com/restapi',
  hostUrl: '/restapi',
};

const dev = {
  hostUrl: 'http://localhost:5000',
  // hostUrl: 'http://tuagom.com:5002', // test
  // hostUrl: 'http://ec2-13-250-30-202.ap-southeast-1.compute.amazonaws.com:5000'
};

const config = process.env.NODE_ENV !== 'production' ? dev : prod;
// console.debug('process.env.NODE_ENV', process.env.NODE_ENV);

// export default {
//   // Add common config values here
//   ...config,
// };

export default config;
