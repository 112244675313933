import React from 'react';
import styled from 'styled-components';
import { DivClick } from '../styles/global';

function Fixture2Student(props) {
  const data = props.data ? props.data : {};
  const index = props.index;
  const update = props.update;
  const player = data.competitors[index];
  let isWin = null;
  if (update) {
    isWin = update.isWin;
  } else {
    isWin =
      player.matchResult && player.matchResult.isWin !== null
        ? player.matchResult.isWin
        : null;
  }
  return (
    <Container onClick={() => props.triggleResult()} {...props}>
      {player.isTeacher ? (
        <Teacher
          src={require('../assets/images/fixture1_teacher.png').default}
        ></Teacher>
      ) : (
        <Student
          src={require('../assets/images/fixture1_student.png').default}
        ></Student>
      )}
      {player.image ? (
        <StudentImage src={player.image}></StudentImage>
      ) : (
        <StudentImage
          src={require('../assets/images/student_image.jpg').default}
        ></StudentImage>
      )}
      {isWin === false ? (
        <FilterLose
          src={require('../assets/images/fixture1_filter_lose.png').default}
        ></FilterLose>
      ) : (
        ''
      )}
      {isWin === true ? (
        <FilterWin
          src={require('../assets/images/fixture1_filter_win.png').default}
        ></FilterWin>
      ) : (
        ''
      )}
      <StudentName>{player.name}</StudentName>
    </Container>
  );
}

const Container = styled(DivClick)`
  position: relative;
  display: flex;
`;

const Teacher = styled.img`
  top: 9px;
  left: 8px;
  width: 164px;
  height: 164px;
  position: absolute;
  object-fit: contain;
`;

const StudentImage = styled.img`
  top: 21px;
  left: 18px;
  width: 144px;
  height: 144px;
  position: absolute;
  object-fit: contain;
  border-radius: 50%;
`;

const Student = styled.img`
  top: 9px;
  left: 6px;
  width: 168px;
  height: 168px;
  position: absolute;
  object-fit: contain;
`;

const FilterLose = styled.img`
  top: 21px;
  left: 18px;
  width: 145px;
  height: 145px;
  position: absolute;
  object-fit: contain;
`;

const FilterWin = styled.img`
  top: 21px;
  left: 18px;
  width: 145px;
  height: 145px;
  position: absolute;
  object-fit: contain;
`;

const StudentName = styled.span`
  font-family: PSLxText;
  top: 139px;
  left: 0px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: white;
  height: 54px;
  width: 168px;
  font-size: 40px;
  text-align: center;
  -webkit-text-stroke: 1px black;
`;

export default Fixture2Student;
