import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import ScoreItem from '../components/score_item';
import {
  readMatchGroup,
  readMatchGroupStudentScoreWithSort,
} from '../services/match_group';
import { ImgClick, ButtonImgClick } from '../styles/global';

const ITEM_DISPLAY_SIZE = 5;

const reMainingDays = (dateString) => {
  console.debug('dateString', dateString);
  const date = new Date(dateString);
  const ret = date - Date.now();
  if (ret <= 0) {
    return '-';
  }
  return Math.floor(ret / 1000 / 60 / 60 / 24);
};

function Score({ history, match }) {
  const { matchGroupId } = match.params;
  const [matchGroup, setMatchGroup] = useState({});
  const [data, setData] = useState({
    items: null,
    pageIndex: null,
    pageTotal: null,
  });
  console.debug('Competition', data);
  const pageIndex = data.pageIndex;
  const pageTotal = data.pageTotal;
  const items = data.items
    ? data.items.slice(
        pageIndex * ITEM_DISPLAY_SIZE,
        pageIndex * ITEM_DISPLAY_SIZE + ITEM_DISPLAY_SIZE
      )
    : null;

  const nextPage = () => {
    if (pageIndex < pageTotal) {
      setData({ ...data, pageIndex: pageIndex + 1 });
    }
  };

  const previousPage = () => {
    if (pageIndex > 0) {
      setData({ ...data, pageIndex: pageIndex - 1 });
    }
  };

  useEffect(() => {
    readMatchGroup(matchGroupId).then((response) => {
      if (response) {
        setMatchGroup({
          ...response,
          reMainingDays: reMainingDays(response.endDate),
        });
      }
    });
    readMatchGroupStudentScoreWithSort(matchGroupId).then((response) => {
      console.debug('readMatchGroupStudentScore', response);
      if (response && response.length > 0) {
        response = response.filter(v => v.isTeacher === false);
        const pageTotal = Math.ceil(response.length / ITEM_DISPLAY_SIZE);
        const pageIndex = 0;
        // response.sort((a, b) => {
        //   if (a.score === null && b.score === null) {
        //     return 0;
        //   } else if (a.score !== null && b.score === null) {
        //     return -1;
        //   } else if (a.score === null && b.score !== null) {
        //     return 1;
        //   }
        //   return b.score !== a.score ? b.score - a.score : b.win - a.win;
        // });
        setData({ ...data, items: response, pageTotal, pageIndex });
      } else {
        setData({ ...data, items: null, pageTotal: null, pageIndex: null });
      }
    });
  }, []);

  const matchClick = () => {
    history.push(`/fixture1/${matchGroupId}`);
  };

  const vsClick = () => {
    history.push(`/fixture2/${matchGroupId}`);
  };

  const chineseWordClick = () => {
    history.push(`/hall-of-fame/${matchGroupId}`);
  };

  const showItems = [];
  if (items) {
    items.forEach((v, index) => {
      showItems.push(
        <HorizontalLine
          key={'H_' + index + 1}
          src={require('../assets/images/score_line_horizontal.png').default}
        ></HorizontalLine>
      );
      showItems.push(
        <ScoreItem
          key={pageIndex * ITEM_DISPLAY_SIZE + index + 1}
          style={{
            //   height: 185,
            height: 170,
            width: 1570,
          }}
          item={{ ...v, no: pageIndex * ITEM_DISPLAY_SIZE + index + 1 }}
        ></ScoreItem>
      );
    });
  }
  showItems.push(
    <HorizontalLine
      key={'H_last'}
      src={require('../assets/images/score_line_horizontal.png').default}
    ></HorizontalLine>
  );

  return (
    <>
      <BgSideLeft
        src={require('../assets/images/score_bg_side.jpg').default}
      ></BgSideLeft>
      <BgSideRight
        src={require('../assets/images/score_bg_side.jpg').default}
      ></BgSideRight>
      <Backgroud
        src={require('../assets/images/score_background.jpg').default}
      ></Backgroud>
      <BarSide
        src={require('../assets/images/score_bar_left_left.png').default}
      ></BarSide>
      <Footer
        src={require('../assets/images/score_footer.png').default}
      ></Footer>
      <Hall
        onClick={() => chineseWordClick()}
        src={require('../assets/images/score_hall.png').default}
      ></Hall>
      <Match
        disabled={matchGroup.isFinish}
        onClick={() => matchClick()}
      ></Match>
      <Back
        onClick={() => history.goBack()}
        src={require('../assets/images/score_back.png').default}
      ></Back>
      <VS disabled={matchGroup.isFinish} onClick={() => vsClick()}></VS>
      <RemainingDaysBackground
        src={require('../assets/images/score_boxendseason.png').default}
      ></RemainingDaysBackground>
      <DaysEndSeason
        src={require('../assets/images/score_end.png').default}
      ></DaysEndSeason>
      <RemainingDays>{matchGroup.reMainingDays}</RemainingDays>
      <ItemList>{showItems}</ItemList>
      <VerticalLine
        src={require('../assets/images/score_line_verical.png').default}
      ></VerticalLine>
      <VerticalLine1
        src={require('../assets/images/score_line_verical.png').default}
      ></VerticalLine1>
      <VerticalLine2
        src={require('../assets/images/score_line_verical.png').default}
      ></VerticalLine2>
      <VerticalLine3
        src={require('../assets/images/score_line_verical.png').default}
      ></VerticalLine3>
      <BackgroudHeader
        src={require('../assets/images/score_bg_header.png').default}
      ></BackgroudHeader>
      <BarRight
        src={require('../assets/images/score_bar_right.png').default}
      ></BarRight>
      <BarLeft
        src={require('../assets/images/score_bar_left.png').default}
      ></BarLeft>
      <ScoreTitle
        src={require('../assets/images/score_score.png').default}
      ></ScoreTitle>
      <Point src={require('../assets/images/score_point.png').default}></Point>
      <Level src={require('../assets/images/score_level.png').default}></Level>
      <Lose src={require('../assets/images/score_lose.png').default}></Lose>
      <Win src={require('../assets/images/score_win.png').default}></Win>
      <Up
        disabled={pageIndex === null || pageIndex === 0}
        onClick={previousPage}
      ></Up>
      <Down
        disabled={pageIndex === null || pageIndex + 1 === pageTotal}
        onClick={nextPage}
      ></Down>
      <GroupName>{matchGroup.name}</GroupName>
    </>
  );
}

const GroupName = styled.span`
  font-family: PSLxText;
  top: 0px;
  left: 0px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  height: 72px;
  width: 331px;
  font-size: 80px;
  text-align: center;
  text-shadow: 0px 5px 5px #000000;
`;

const BgSideLeft = styled.img`
  top: 0px;
  left: 0px;
  width: 611px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const BgSideRight = styled.img`
  top: 0px;
  left: 1309px;
  width: 611px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const Backgroud = styled.img`
  top: 0px;
  left: 361px;
  width: 1227px;
  height: 1080px;
  position: absolute;
  object-fit: stretch;
`;

const BarSide = styled.img`
  top: 0px;
  left: 0px;
  width: 117px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const Footer = styled.img`
  top: 1056px;
  left: 0px;
  width: 1920px;
  height: 24px;
  position: absolute;
  object-fit: contain;
`;

const Hall = styled(ImgClick)`
  top: 776px;
  left: 1688px;
  width: 136px;
  height: 137px;
  position: absolute;
  object-fit: contain;
`;

const Match = styled(ButtonImgClick)`
  top: 618px;
  left: 1690px;
  width: 134px;
  height: 137px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/score_match.png').default});
`;

const Back = styled(ImgClick)`
  top: 952px;
  left: 1663px;
  width: 185px;
  height: 81px;
  position: absolute;
  object-fit: contain;
`;

const VS = styled(ButtonImgClick)`
  top: 458px;
  left: 1688px;
  width: 141px;
  height: 141px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/score_vs.png').default});
`;

const RemainingDaysBackground = styled.img`
  top: 108px;
  left: 1623px;
  width: 286px;
  height: 316px;
  position: absolute;
  object-fit: contain;
`;

const DaysEndSeason = styled.img`
  top: 301px;
  left: 1632px;
  width: 268px;
  height: 123px;
  position: absolute;
  object-fit: contain;
`;

const RemainingDays = styled.span`
  font-family: PSLxText;
  top: 93px;
  left: 1622px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  height: 227px;
  width: 268px;
  font-size: 200px;
  text-align: center;
  -webkit-text-stroke: 5px white;
`;

const ItemList = styled.div`
  /* overflow-y: hidden; */
  /* overflow: hidden; */
  /* overflow: scroll; */
  top: 93px;
  left: 0px;
  /* width: 1555px; */
  width: 1600px;
  height: 971px;
  position: absolute;
  /* z-index: 1; */
  /* display: list-item; */
  /* flex-direction: column; */
  /* justify-content: flex-start;  */
  /* flex-basis: auto; */
  /* flex-wrap: wrap; */
  /* flex-flow: column wrap; */
  /* flex: 1 1 auto; */
`;

const HorizontalLine = styled.img`
  width: 1560px;
  height: 13px;
  object-fit: contain;
`;

const HorizontalLine1 = styled.img`
  width: 1560px;
  height: 100%;
  object-fit: contain;
`;

const HorizontalLine2 = styled.img`
  width: 1560px;
  height: 100%;
  object-fit: contain;
`;

const VerticalLine = styled.img`
  top: -24px;
  left: 625px;
  width: 13px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const VerticalLine1 = styled.img`
  top: -28px;
  left: 859px;
  width: 13px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const VerticalLine2 = styled.img`
  top: -28px;
  left: 1081px;
  width: 13px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const VerticalLine3 = styled.img`
  top: -24px;
  left: 1296px;
  width: 13px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const BackgroudHeader = styled.img`
  top: -1px;
  left: 0px;
  width: 1920px;
  height: 94px;
  position: absolute;
  object-fit: contain;
`;

const BarRight = styled.img`
  top: 0px;
  left: 1555px;
  width: 65px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const BarLeft = styled.img`
  top: 0px;
  left: 331px;
  width: 60px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const ScoreTitle = styled.img`
  top: 19px;
  left: 1336px;
  width: 188px;
  height: 40px;
  position: absolute;
  object-fit: contain;
`;

const Point = styled.img`
  top: 18px;
  left: 446px;
  width: 164px;
  height: 40px;
  position: absolute;
  object-fit: contain;
`;

const Level = styled.img`
  top: 19px;
  left: 673px;
  width: 163px;
  height: 38px;
  position: absolute;
  object-fit: contain;
`;

const Lose = styled.img`
  top: 18px;
  left: 1126px;
  width: 139px;
  height: 40px;
  position: absolute;
  object-fit: contain;
`;

const Win = styled.img`
  top: 19px;
  left: 919px;
  width: 102px;
  height: 38px;
  position: absolute;
  object-fit: contain;
`;

const Up = styled(ButtonImgClick)`
  top: 93px;
  left: 295px;
  width: 72px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/score_up.png').default});
`;

const Down = styled(ButtonImgClick)`
  top: 993px;
  left: 290px;
  width: 71px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/score_down.png').default});
`;

export default Score;
