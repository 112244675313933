import React from "react";
import styled from "styled-components";
import Fixture1Student from "./Fixture1Student";

function HallOfFameItem(props) {
  const data = props.data;
  const no = props.no;
  return (
    <Container {...props}>
      <Star src={require("../assets/images/hall_of_fame_star.png").default}></Star>
      <No>{no}</No>
      <Fixture1Student
        style={{
          position: "absolute",
          left: 225,
          top: -2,
          width: 180,
          height: 190
        }}
        data={data}
      ></Fixture1Student>
      <HorizontalLine
        src={require("../assets/images/hall_of_fame_horizontal_line.png").default}
      ></HorizontalLine>
      <BgOne src={require("../assets/images/hall_of_fame_one.png").default}></BgOne>
      <One>{data.rank['1']}</One>
      <BgThree
        src={require("../assets/images/hall_of_fame_three.png").default}
      ></BgThree>
      <Three>{data.rank['3']}</Three>
      <BgTwo src={require("../assets/images/hall_of_fame_two.png").default}></BgTwo>
      <Two>{data.rank['2']}</Two>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
`;

const Star = styled.img`
  top: 9px;
  left: 20px;
  width: 170px;
  height: 168px;
  position: absolute;
  object-fit: contain;
`;

const No = styled.span`
  font-family: PSLxText;
  top: 40px;
  left: 70px;
  width: 70px;
  text-align: center;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  font-size: 80px;
  -webkit-text-stroke: 2px white;
`;

const HorizontalLine = styled.img`
  top: 178px;
  left: 102px;
  /* width: 1278px; */
  width: 1278px;
  height: 13px;
  position: absolute;
  object-fit: contain;
`;

const BgOne = styled.img`
  top: 12px;
  left: 702px;
  width: 166px;
  height: 165px;
  position: absolute;
  object-fit: contain;
`;

const One = styled.span`
  font-family: PSLxText;
  top: 34px;
  left: 708px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: rgba(255,255,255,1);
  font-size: 100px;
  text-align: center;
  width: 150px;
`;

const BgThree = styled.img`
  top: 13px;
  left: 1175px;
  width: 166px;
  height: 164px;
  position: absolute;
  object-fit: contain;
`;

const Three = styled.span`
  font-family: PSLxText;
  top: 34px;
  left: 1185px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: rgba(255,255,255,1);
  font-size: 100px;
  text-align: center;
  width: 150px;
`;

const BgTwo = styled.img`
  top: 12px;
  left: 941px;
  width: 166px;
  height: 165px;
  position: absolute;
  object-fit: contain;
`;

const Two = styled.span`
  font-family: PSLxText;
  top: 30px;
  left: 950px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: rgba(255,255,255,1);
  font-size: 100px;
  text-align: center;
  width: 150px;
`;

export default HallOfFameItem;
