import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Fixture2Match from '../components/Fixture2Match';
import { ButtonImgClick, InputImgBackgroud } from '../styles/global';

import {
  readMatchGroupStudentScore,
  readMatches,
  readMatchGroupsActive,
  editMatcheResults,
} from '../services/match_group';

import { handleError, handleSuccess } from '../services/handleResponse';
import { confirmMove } from '../components/messageUtil';

const ITEM_DISPLAY_SIZE = 12;

function Fixture2({ history, match }) {
  const { matchGroupId } = match.params;
  const [data, setData] = useState({});
  const [formData, setFormData] = useState({});
  const [isPass, setIsPass] = useState(false);
  const matchGroups = data.matchGroups;
  const roundItems = data.roundItems;
  const roundIndex = data.roundIndex;
  const dayItems = data.dayItems;
  const dayIndex = data.dayIndex;
  const matchGroupIndex = data.matchGroupIndex;
  const matches = data.matches;
  const matchGroupIndexTemp = matchGroupIndex ? matchGroupIndex : 0;
  const matchGroup = matchGroups ? matchGroups[matchGroupIndexTemp] : null;
  const updateData = data.updateData ? data.updateData : {};
  const pageIndex = data.pageIndex;
  const pageTotal = data.pageTotal;
  const items = data.items
    ? data.items.slice(
      pageIndex * ITEM_DISPLAY_SIZE,
      pageIndex * ITEM_DISPLAY_SIZE + ITEM_DISPLAY_SIZE
    )
    : null;

  const displayItems = items
    ? items.map((v, index) => (
      <Fixture2Match
        key={index + 1}
        style={{
          height: 234,
          width: 638,
        }}
        data={v}
        round={roundItems[roundIndex]}
        update={updateData[v.matchId]}
        triggleResult={(playerIndex) => triggleResult(v, playerIndex)}
      ></Fixture2Match>
    ))
    : '';

  const onInputChange = (e) => {
    const temp = { ...formData, [e.target.name]: e.target.value };
    console.debug('onChange', temp);
    setFormData(temp);
  };

  const day =
    dayIndex !== null && dayIndex !== undefined ? dayItems[dayIndex] : '';

  const round =
    roundIndex !== null && roundIndex !== undefined
      ? roundItems[roundIndex]
      : '';

  const RESULT_VALUE = {
    0: null,
    1: true,
    2: false,
    null: 0,
    true: 1,
    false: 2,
  };

  const resultNext = (value) => {
    return RESULT_VALUE[(RESULT_VALUE[value] + 1) % 3];
  };

  const triggleResult = (match, playerIndex) => {
    console.debug('triggleResult.match', match);
    console.debug('triggleResult.playerIndex', playerIndex);
    const competitor = match.competitors[playerIndex];
    console.debug('triggleResult.matchId', competitor.matchResult.matchId);
    const matchId = competitor.matchResult.matchId;
    const oldIsWin = competitor.matchResult.isWin;
    let newIsWin;
    console.debug('triggleResult.isWin', competitor.matchResult.isWin);
    if (updateData[matchId] !== undefined) {
      newIsWin = resultNext(updateData[matchId].isWin);
      if (updateData[matchId].originalIsWin === newIsWin) {
        delete updateData[matchId];
      } else {
        updateData[matchId].isWin = newIsWin;
      }
    } else {
      newIsWin = resultNext(oldIsWin);
      updateData[matchId] = {
        isWin: newIsWin,
        studentId: competitor.id,
        originalIsWin: oldIsWin,
      };
    }

    console.debug('updateData', updateData);
    setData({ ...data, updateData });
  };

  const canChangePage = async () => {
    if (updateData && Object.keys(updateData).length > 0) {
      const result = await confirmMove();
      console.debug('confirmMove', result);
      if (result.isConfirmed == false) {
        return false;
      }
    }
    return true;
  };

  const nextPage = () => {
    if (pageIndex < pageTotal) {
      setData({ ...data, pageIndex: pageIndex + 1 });
    }
  };

  const previousPage = () => {
    if (pageIndex > 0) {
      setData({ ...data, pageIndex: pageIndex - 1 });
    }
  };

  console.debug('Fixture2:data', data);

  const back = async () => {
    if (!(await canChangePage())) return;
    history.goBack();
  };

  const setItemsData = (matchList, round, day) => {
    const itemsTemp = [];
    for (let match of matchList) {
      if (match.round == round && match.match == day) {
        if (match.students.filter((v) => v.isTeacher === false).length > 0) {
          itemsTemp.push({
            matchId: match.id,
            competitors: match.students,
            no: match.no,
          });
        }
      }
    }
    if (itemsTemp && itemsTemp.length > 0) {
      data.pageTotal = Math.ceil(itemsTemp.length / ITEM_DISPLAY_SIZE);
      data.pageIndex = data.pageIndex ? data.pageIndex : 0;
    } else {
      data.pageTotal = null;
      data.pageIndex = null;
    }
    data.items = itemsTemp;
  };

  const readMatchDetail = async (matchGroupParam) => {
    const matchesTemp = await readMatches(matchGroupParam.id);
    const roundItemsTemp = [];
    const dayItemsTemp = [];
    for (let d of matchesTemp) {
      if (!roundItemsTemp.find((v) => v == d.round)) {
        roundItemsTemp.push(d.round);
      }
      if (!dayItemsTemp.find((v) => v == d.match)) {
        dayItemsTemp.push(d.match);
      }
    }
    data.matches = matchesTemp;
    data.roundIndex = data.roundIndex | 0;
    data.dayIndex = data.dayIndex | 0;
    data.dayItems = dayItemsTemp;
    data.roundItems = roundItemsTemp;
    setItemsData(
      matchesTemp,
      roundItemsTemp[data.roundIndex],
      dayItemsTemp[data.dayIndex]
    );
    console.debug('readMatchDetail', data);
  };

  const save = async () => {
    console.debug('save', updateData);
    const results = [];
    for (let matchId in updateData) {
      results.push({
        matchId,
        isWin: updateData[matchId].isWin,
        studentId: updateData[matchId].studentId,
      });
    }
    try {
      const responseData = await editMatcheResults(formData.password, results);
      if (responseData !== null) {
        handleSuccess();
        await readMatchDetail(matchGroup);
        setData({ ...data, updateData: {} });
      }
    } catch (err) {
      console.debug('editMatcheResults', err);
      handleError(err);
    }
  };

  useEffect(() => {
    const func = async () => {
      const matchGroupsResponse = await readMatchGroupsActive();
      if (matchGroupsResponse === null) {
        return;
      }
      data.matchGroups = matchGroupsResponse;
      if (matchGroupId !== undefined) {
        const found = matchGroupsResponse.findIndex(
          (v) => v.id == matchGroupId
        );
        // console.debug('found', found);
        if (found !== -1) {
          data.matchGroupIndex = found;
          await readMatchDetail(matchGroupsResponse[found]);
        }
      } else if (matchGroupsResponse.length > 0) {
        data.matchGroupIndex = 0;
        await readMatchDetail(matchGroupsResponse[0]);
      }
      setData({ ...data });
      console.debug('useEffect', data);
    };
    func();
  }, []);

  const matchGroupNext = async () => {
    if (!(await canChangePage())) return;
    if (matchGroups && matchGroupIndex < matchGroups.length - 1) {
      await readMatchDetail(matchGroups[matchGroupIndex + 1]);
      setData({
        ...data,
        updateData: {},
        matchGroupIndex: matchGroupIndex + 1,
      });
    }
    console.debug('matchGroupNext', data);
  };

  const matchGroupPrevious = async () => {
    if (!(await canChangePage())) return;
    if (matchGroups && matchGroupIndex > 0) {
      await readMatchDetail(matchGroups[matchGroupIndex - 1]);
      setData({
        ...data,
        updateData: {},
        matchGroupIndex: matchGroupIndex - 1,
      });
    }
    console.debug('matchGroupPrevious', data);
  };

  const dayNext = async () => {
    if (!(await canChangePage())) return;
    console.debug('dayNext', { dayItems, dayIndex });
    if (dayItems && dayIndex < dayItems.length - 1) {
      console.debug('dayNext.setItemsData');
      setItemsData(matches, roundItems[roundIndex], dayItems[dayIndex + 1]);
      setData({ ...data, updateData: {}, dayIndex: dayIndex + 1 });
    }
  };

  const dayPrevious = async () => {
    if (!(await canChangePage())) return;
    if (dayItems && dayIndex > 0) {
      setItemsData(matches, roundItems[roundIndex], dayItems[dayIndex - 1]);
      setData({ ...data, updateData: {}, dayIndex: dayIndex - 1 });
    }
    console.debug('dayPreivous', data);
  };

  const roundNext = async () => {
    if (!(await canChangePage())) return;
    if (roundItems && roundIndex < roundItems.length - 1) {
      setItemsData(matches, roundItems[roundIndex + 1], dayItems[dayIndex]);
      setData({ ...data, updateData: {}, roundIndex: roundIndex + 1 });
      console.debug('roundNext');
    }
  };

  const roundPreivous = async () => {
    if (!(await canChangePage())) return;
    if (roundItems && roundIndex > 0) {
      setItemsData(matches, roundItems[roundIndex - 1], dayItems[dayIndex]);
      setData({ ...data, updateData: {}, roundIndex: roundIndex - 1 });
    }
    console.debug('roundPreivous', data);
  };

  return (
    <>
      <Background
        src={require('../assets/images/fixture2_background.jpg').default}
      ></Background>
      <ScrollArea>{displayItems}</ScrollArea>
      <Header
        src={require('../assets/images/fixture2_header.png').default}
      ></Header>
      <GroupPrevious
        onClick={() => matchGroupPrevious()}
        disabled={
          matchGroupIndex === null ||
          matchGroupIndex === undefined ||
          matchGroupIndex <= 0
        }
      ></GroupPrevious>
      <GroupNext
        onClick={() => matchGroupNext()}
        disabled={
          matchGroupIndex === null ||
          matchGroupIndex === undefined ||
          matchGroupIndex >= matchGroups.length - 1
        }
      ></GroupNext>
      <div onClick={() => { setIsPass(true) }}>
        <GroupName>{matchGroup ? matchGroup.name : ''}</GroupName>
      </div>
      <Footer
        src={require('../assets/images/fixture2_footer.png').default}
      ></Footer>
      <VerticalBar1
        src={require('../assets/images/fixture2_vertical_bar.png').default}
      ></VerticalBar1>
      <VerticalBar2
        src={require('../assets/images/fixture2_vertical_bar.png').default}
      ></VerticalBar2>
      <DayPrevious
        onClick={() => dayPrevious()}
        disabled={dayIndex === null || dayIndex === undefined || dayIndex <= 0}
      ></DayPrevious>
      <DayNext
        onClick={() => dayNext()}
        disabled={
          dayIndex === null ||
          dayIndex === undefined ||
          dayIndex >= dayItems.length - 1
        }
      ></DayNext>
      <Day>{day}</Day>
      <RoundNext
        onClick={() => roundNext()}
        disabled={
          roundIndex === null ||
          roundIndex === undefined ||
          roundIndex >= roundItems.length - 1
        }
      ></RoundNext>
      <RoundName>ROUND {round}</RoundName>
      <RoundPrevious
        onClick={() => roundPreivous()}
        disabled={
          roundIndex === null || roundIndex === undefined || roundIndex <= 0
        }
      ></RoundPrevious>
      <Back onClick={() => back()}></Back>
      <Ok
        onClick={() => {
          if (isPass) {
            setIsPass(false);
            save();
          }
        }}
        disabled={updateData && Object.keys(updateData).length === 0}
      ></Ok>
      <Password
        name="password"
        // onChange={onInputChange}
        type="password"
        disabled="true"
      // src={require('../assets/images/fixture2_boxpass.png').default}
      ></Password>
      <LabelPassword
        src={require('../assets/images/fixture2_password.png').default}
      ></LabelPassword>
      <Up
        disabled={
          pageIndex === null || pageIndex === undefined || pageIndex === 0
        }
        onClick={previousPage}
      ></Up>
      <Down
        disabled={
          pageIndex === null ||
          pageIndex === undefined ||
          pageIndex + 1 === pageTotal
        }
        onClick={nextPage}
      ></Down>
      {/* <LabelBlack
        src={require('../assets/images/fixture2_black.png').default}
      ></LabelBlack>
      <LabelWhite
        src={require('../assets/images/fixture2_white.png').default}
      ></LabelWhite> */}
    </>
  );
}

const Background = styled.img`
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const ScrollArea = styled.div`
  /* overflow-y: scroll; */
  top: 124px;
  left: 0px;
  width: 1920px;
  height: 935px;
  position: absolute;
  flex-direction: row;
  flex-wrap: wrap;
  /* overflow: hidden; */
  display: flex;
  align-content: flex-start;
`;

const Header = styled.img`
  top: 0px;
  width: 1920px;
  height: 124px;
  position: absolute;
  left: 0px;
  object-fit: contain;
`;

const GroupPrevious = styled(ButtonImgClick)`
  top: 25px;
  left: 17px;
  width: 71px;
  height: 72px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture2_previous_red.png')
    .default});
`;

const GroupNext = styled(ButtonImgClick)`
  top: 25px;
  left: 537px;
  width: 71px;
  height: 72px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture2_next_red.png')
    .default});
`;

const GroupName = styled.span`
  font-family: PSLxText;
  top: 25px;
  left: 113px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  height: 72px;
  width: 397px;
  font-size: 50px;
  text-align: center;
`;

const Footer = styled.img`
  top: 1059px;
  left: 0px;
  width: 1920px;
  height: 21px;
  position: absolute;
  object-fit: contain;
`;

const VerticalBar1 = styled.img`
  top: 0px;
  left: 630px;
  width: 19px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const VerticalBar2 = styled.img`
  top: 0px;
  left: 1270px;
  width: 19px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const DayPrevious = styled(ButtonImgClick)`
  top: 22px;
  left: 663px;
  width: 72px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture2_previous.png')
    .default});
`;

const DayNext = styled(ButtonImgClick)`
  top: 22px;
  left: 808px;
  width: 71px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture2_next.png')
    .default});
`;

const Day = styled.span`
  font-family: PSLxText;
  top: 26px;
  left: 726px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  height: 72px;
  width: 81px;
  font-size: 50px;
  text-align: center;
`;

const RoundNext = styled(ButtonImgClick)`
  top: 22px;
  left: 1188px;
  width: 71px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture2_next.png')
    .default});
`;

const RoundName = styled.span`
  font-family: PSLxText;
  top: 26px;
  left: 967px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  height: 65px;
  width: 232px;
  font-size: 50px;
  text-align: center;
`;

const RoundPrevious = styled(ButtonImgClick)`
  top: 22px;
  left: 903px;
  width: 72px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture2_previous.png')
    .default});
`;

const Back = styled(ButtonImgClick)`
  top: 17px;
  left: 1722px;
  width: 185px;
  height: 81px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture2_back.png')
    .default});
`;

const Ok = styled(ButtonImgClick)`
  top: 21px;
  left: 1561px;
  width: 122px;
  height: 73px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture2_ok.png').default});
`;

// const Password = styled.img`
const Password = styled(InputImgBackgroud)`
  font-family: PSLxText;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  top: 48px;
  left: 1318px;
  width: 197px;
  height: 46px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture2_boxpass.png')
    .default});
`;

const LabelPassword = styled.img`
  top: 17px;
  left: 1334px;
  width: 165px;
  height: 30px;
  position: absolute;
  object-fit: contain;
`;

const Up = styled(ButtonImgClick)`
  top: 108px;
  left: 1841px;
  width: 72px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture2_up.png').default});
`;

const Down = styled(ButtonImgClick)`
  top: 1001px;
  left: 1836px;
  width: 71px;
  height: 71px;
  position: absolute;
  object-fit: contain;
  background-image: url(${require('../assets/images/fixture2_down.png')
    .default});
`;

// const LabelBlack = styled.img`
//   top: 124px;
//   left: 673px;
//   width: 135px;
//   height: 46px;
//   position: absolute;
//   object-fit: contain;
// `;

// const LabelWhite = styled.img`
//   top: 124px;
//   left: 1111px;
//   width: 133px;
//   height: 46px;
//   position: absolute;
//   object-fit: contain;
// `;

export default Fixture2;
