import React from 'react';
import styled from 'styled-components';
import Fixture2Student from './Fixture2Student';

function Fixture2Match(props) {
  let updatePlayer1 = null;
  let updatePlayer2 = null;
  const data = props.data;
  const update = props.update;
  if (update) {
    const index = data.competitors.findIndex((v) => v.id === update.studentId);
    if (index !== -1) {
      if (index === 0) {
        updatePlayer1 = {
          isWin: update.isWin !== null ? update.isWin : null,
          studentId: data.competitors[0].studentId,
        };
        updatePlayer2 = {
          isWin: update.isWin !== null ? !update.isWin : null,
          studentId: data.competitors[1].studentId,
        };
      } else {
        updatePlayer2 = {
          isWin: update.isWin !== null ? update.isWin : null,
          studentId: data.competitors[0].studentId,
        };
        updatePlayer1 = {
          isWin: update.isWin !== null ? !update.isWin : null,
          studentId: data.competitors[1].studentId,
        };
      }
    }
  }

  return (
    <Container {...props}>
      {props.round % 2 == 0 ? (
        <>
          <LabelBlack
            src={require('../assets/images/fixture2_black.png').default}
          ></LabelBlack>
          <LabelWhite
            src={require('../assets/images/fixture2_white.png').default}
          ></LabelWhite>
        </>
      ) : (
        <>
          <LabelBlack
            src={require('../assets/images/fixture2_white.png').default}
          ></LabelBlack>
          <LabelWhite
            src={require('../assets/images/fixture2_black.png').default}
          ></LabelWhite>
        </>
      )}
      <Fixture2Student
        style={{
          position: 'absolute',
          left: 16,
          top: 42,
          width: 180,
          height: 190,
        }}
        data={props.data}
        update={updatePlayer1}
        index={0}
        triggleResult={() => props.triggleResult(0)}
      ></Fixture2Student>
      <Fixture2Student
        style={{
          position: 'absolute',
          left: 442,
          top: 42,
          width: 180,
          height: 190,
        }}
        data={props.data}
        update={updatePlayer2}
        index={1}
        triggleResult={() => props.triggleResult(1)}
      ></Fixture2Student>
      <Vs src={require('../assets/images/fixture2_vs.png').default}></Vs>
      <TableBackground
        src={require('../assets/images/fixture2_table.png').default}
      ></TableBackground>
      <Table>TABLE {props.data.no}</Table>
      <Line src={require('../assets/images/fixture2_line.png').default}></Line>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
`;

const Vs = styled.img`
  top: 87px;
  width: 138px;
  height: 91px;
  position: absolute;
  left: 245px;
  object-fit: contain;
`;

const TableBackground = styled.img`
  top: 3px;
  left: 196px;
  width: 256px;
  height: 74px;
  position: absolute;
  object-fit: contain;
`;

const Table = styled.span`
  font-family: PSLxText;
  top: 18px;
  left: 215px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  height: 40px;
  width: 221px;
  font-size: 35px;
  text-align: center;
`;

const Line = styled.img`
  top: 226px;
  left: 0px;
  width: 638px;
  height: 8px;
  position: absolute;
  object-fit: contain;
`;

const LabelBlack = styled.img`
  top: 0px;
  left: 40px;
  width: 135px;
  height: 46px;
  position: absolute;
  object-fit: contain;
`;

const LabelWhite = styled.img`
  top: 0px;
  left: 465px;
  width: 133px;
  height: 46px;
  position: absolute;
  object-fit: contain;
`;

export default Fixture2Match;
