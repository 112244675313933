import React, { useEffect, useState } from 'react';
// import styled, { css } from 'styled-components';

let counter = 0;
let counterValue1 = 0;
let counterValue2 = 0;
let counterValue3 = 0;

function Test(props) {
  const [value1, setValue1] = useState(null);
  const [value2, setValue2] = useState(null);
  const [value3, setValue3] = useState(null);
  counter += 1;

  console.debug('counter', counter);

  const clickValue1 = () => {
    counterValue1 += 1;
    console.debug('clickValue1', counterValue1);
    setValue1(counterValue1);
    clickValue2();
    clickValue3();
  };

  const clickValue2 = () => {
    counterValue2 += 1;
    console.debug('clickValue2', counterValue2);
    setValue2(counterValue2);
  };

  const clickValue3 = () => {
    counterValue3 += 1;
    console.debug('clickValue3', counterValue3);
    setValue3(counterValue3);
  };

  useEffect(() => {
    setValue1(counterValue1);
    setValue2(counterValue2);
    setValue3(counterValue3);
  }, []);

  return (
    <>
      <span>{`counter: ${counter}`}</span>
      <span>{`value1: ${value1}`}</span>
      <button
        onClick={() => {
          clickValue1();
        }}
      >
        value1
      </button>
      <span>{`value2: ${value2}`}</span>
      <button
        onClick={() => {
          clickValue2();
        }}
      >
        value2
      </button>
      <span>{`value3: ${value3}`}</span>
      <button
        onClick={() => {
          clickValue3();
        }}
      >
        value3
      </button>
    </>
  );
}

export default Test;
