import React from 'react';
import styled from 'styled-components';
import { DivClick } from '../styles/global';

const dateShow = (dateString) => {
  
  console.debug('dateString', dateString);
  const date = new Date(dateString);
  var year = date.getFullYear();
  console.debug('year', year);
  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;
  return day + '/' + month + '/' + ('' + year).substr(2,2);
};

function GroupItem(props) {
  // console.debug('GroupItem', props);
  return (
    <Container {...props}>
      <Bg
        src={require('../assets/images/competition_item_bg.png').default}
      ></Bg>
      {props.item.imageUrl ? (
        <Logo src={props.item.imageUrl}></Logo>
      ) : (
        <Logo
          src={require('../assets/images/competition_item_logo.png').default}
        ></Logo>
      )}
      <Title
        src={require('../assets/images/competition_item_title.png').default}
      ></Title>
      <Group1>{props.item.name}</Group1>
      {props.item.isFinish ? (
        <FinishDiv>
          <FinishDate>{dateShow(props.item.endDate)}</FinishDate>
        </FinishDiv>
      ) : (
        ''
      )}
    </Container>
  );
}

const Container = styled(DivClick)`
  position: relative;
  display: flex;
`;

const Bg = styled.img`
  top: 36px;
  left: 0px;
  width: 402px;
  height: 233px;
  position: absolute;
  object-fit: contain;
`;

const Logo = styled.img`
  top: 57px;
  left: 105px;
  width: 192px;
  height: 192px;
  position: absolute;
  object-fit: contain;
  border-radius: 50%;
`;

const Title = styled.img`
  top: 0px;
  left: 70px;
  width: 256px;
  height: 74px;
  position: absolute;
  object-fit: contain;
`;

const Group1 = styled.span`
  font-family: PSLxText;
  top: 10px;
  left: 0px;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  height: 52px;
  width: 402px;
  text-align: center;
  font-size: 40px;
`;

const FinishDiv = styled.div`
  top: 116px;
  left: 51px;
  width: 305px;
  height: 100px;
  position: absolute;
  border-width: 5px;
  border-color: rgba(0, 0, 0, 0.5);
  /* opacity: 0.5; */
  border-radius: 5px;
  border-style: solid;
  background-color: rgba(255, 255, 255, 0.5);
`;

const FinishDate = styled.div`
  font-family: PSLxText;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  font-size: 50px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export default GroupItem;
