import React from 'react';
import styled from 'styled-components';
import { ImgClick } from '../styles/global';

function ScoreTable({ history }) {
  return (
    <>
      <Screen
        src={require('../assets/images/score_table.jpg').default}
      ></Screen>
      <Back
        onClick={() => history.goBack()}
        src={require('../assets/images/back.png').default}
      ></Back>
    </>
  );
}

const Screen = styled.img`
  left: 0px;
  width: 1920px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const Back = styled(ImgClick)`
  top: 949px;
  left: 1693px;
  width: 185px;
  height: 81px;
  position: absolute;
  object-fit: contain;
`;

export default ScoreTable;
