import { saveSuccess, errorMessage } from '../components/messageUtil';

export const handleSuccess = () => {
  return saveSuccess();
};

export const displayError = (message) => {
  errorMessage(message);
};

export const handleError = (error) => {
  try {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const err = error.response.data.errors;
      if (typeof err == 'string') errorMessage(err);
      else {
        if (err.length) {
          errorMessage(err.map((v) => v.msg? v.msg: v.message).join('\n'));
        } else {
          errorMessage(err.msg);
        }
      }
      // errorMessage(error.response.status);
      // errorMessage(error.response.headers);
    } else if (error.message) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      errorMessage(error.message);
    } else {
      // Something happened in setting up the request that triggered an Error
      errorMessage('Error', error);
    }
  } catch (err) {
    console.error(err);
    errorMessage('Internal server error');
  }
};
