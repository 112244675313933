import React from 'react';
import styled from 'styled-components';

function ScoreItem(props) {
  const item = props.item;
  return (
    <Container {...props}>
      <LoseBackground
        src={require('../assets/images/score_bg_lose.png').default}
      ></LoseBackground>
      <PointBackground
        src={require('../assets/images/score_bg_point.png').default}
      ></PointBackground>
      <ScoreBackground
        src={require('../assets/images/score_bg_score.png').default}
      ></ScoreBackground>
      <LevelBackground
        src={require('../assets/images/score_bg_point.png').default}
      ></LevelBackground>
      <WinBackground
        src={require('../assets/images/score_bg_win.png').default}
      ></WinBackground>
      <Star src={require('../assets/images/score_star.png').default}></Star>
      <No>{item.no}</No>

      <Student
        src={require('../assets/images/score_student.png').default}
      ></Student>
      {item.imageUrl ? (
        <StudentImage src={item.imageUrl}></StudentImage>
      ) : (
        <StudentImage
          src={require('../assets/images/score_student_image.jpg').default}
        ></StudentImage>
      )}
      <NameDiv>
        <StudentName>{item.name}</StudentName>
      </NameDiv>
      <Point>{item.point | 0}</Point>
      <Level>{item.level}</Level>
      <Win>{item.win | 0}</Win>
      <Lose>{item.lose | 0}</Lose>
      <Score>{item.score && item.score > 0 ? item.score : 0}</Score>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
`;

const LoseBackground = styled.img`
  width: 153px;
  height: 152px;
  position: absolute;
  top: 13px;
  left: 1132px;
  object-fit: contain;
`;

const PointBackground = styled.img`
  width: 156px;
  height: 154px;
  position: absolute;
  top: 11px;
  left: 680px;
  object-fit: contain;
`;

const ScoreBackground = styled.img`
  width: 156px;
  height: 154px;
  position: absolute;
  top: 12px;
  left: 1356px;
  object-fit: contain;
`;

const LevelBackground = styled.img`
  width: 156px;
  height: 154px;
  position: absolute;
  top: 12px;
  left: 446px;
  object-fit: contain;
`;

const WinBackground = styled.img`
  width: 153px;
  height: 152px;
  position: absolute;
  top: 13px;
  left: 908px;
  object-fit: contain;
`;

const Star = styled.img`
  top: 30px;
  left: 0px;
  width: 102px;
  height: 108px;
  position: absolute;
  object-fit: contain;
`;

const No = styled.span`
  font-family: PSLxText;
  font-style: normal;
  font-weight: 700;
  /* font-weight: bold; */
  color: #121212;
  height: 70px;
  width: 58px;
  /* font-size: 60px; */
  font-size: 90px;
  text-align: center;
  position: absolute;
  /* top: 54px; */
  top: 35px;
  left: 25px;
  -webkit-text-stroke-color: white;
  -webkit-text-stroke-width: 2px;
`;

const Student = styled.img`
  top: 0px;
  left: 142px;
  width: 168px;
  height: 168px;
  position: absolute;
  object-fit: contain;
`;

const StudentImage = styled.img`
  top: 15px;
  left: 155px;
  width: 140px;
  height: 140px;
  position: absolute;
  object-fit: contain;
  border-radius: 50%;
`;

const NameDiv = styled.div`
  top: 140px;
  left: 110px;
  position: absolute;
  width: 230px;
  text-align: center;
`;

const StudentName = styled.span`
  font-family: PSLxText;
  position: relative;
  font-style: normal;
  font-weight: 700;
  /* color: rgba(0, 0, 0, 1); */
  color: white;
  /* background-color: rgba(120, 120, 120, 0.2); */
  border-radius: 10px;
  font-size: 40px;
  padding: 5px;
  -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 1px;
`;

const Point = styled.span`
  font-family: PSLxText;
  /* top: 54px; */
  top: 35px;
  left: 435px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  height: 64px;
  width: 181px;
  /* font-size: 60px; */
  font-size: 90px;
  text-align: center;
  text-shadow: 0px 3px 5px #000000;
`;

const Level = styled.span`
  font-family: PSLxText;
  top: 25px;
  left: 667px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  height: 83px;
  width: 181px;
  font-size: 110px;
  text-align: center;
  text-shadow: 0px 3px 5px #000000;
`;

const Win = styled.span`
  font-family: PSLxText;
  top: 25px;
  left: 894px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  height: 99px;
  width: 181px;
  font-size: 110px;
  text-align: center;
  text-shadow: 0px 3px 5px #000000;
`;

const Lose = styled.span`
  font-family: PSLxText;
  top: 25px;
  left: 1118px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  height: 99px;
  width: 181px;
  font-size: 110px;
  text-align: center;
  text-shadow: 0px 3px 5px #000000;
`;

const Score = styled.span`
  font-family: PSLxText;
  top: 25px;
  left: 1344px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  height: 99px;
  width: 181px;
  font-size: 110px;
  text-align: center;
  text-shadow: 0px 3px 5px #000000;
`;

export default ScoreItem;
