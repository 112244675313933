import React from "react";
import styled, { css } from "styled-components";
import Fixture1Student from "./Fixture1Student";

function Fixture1StudentNo(props) {
  return (
    <Container {...props}>
      <StudentNo1
        src={require("../assets/images/fixture1_student_no.png").default}
      ></StudentNo1>
      <Fixture1Student
        triggleResult={props.triggleResult}
        data={props.data}
        update={props.update}
        style={{
          position: "absolute",
          top: 45,
          height: 184,
          width: 179,
          left: 12
        }}
      ></Fixture1Student>
      <Center horizontal>
        <No>{props.no}</No>
      </Center>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
`;

const StudentNo1 = styled.img`
  top: 0px;
  left: 1px;
  width: 197px;
  height: 45px;
  position: absolute;
  object-fit: contain;
`;

const Center = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  pointer-events: none;

  ${props =>
    ((props.horizontal && !props.vertical) ||
      (!props.horizontal && !props.vertical)) &&
    css`
      align-items: center;
  `};


  ${props =>
    ((props.vertical && !props.horizontal) ||
      (!props.horizontal && !props.vertical)) &&
    css`
      justify-content: center;
  `};
  `;

const No = styled.span`
  font-family: PSLxText;
  top: -5px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  height: 36px;
  width: 97px;
  font-size: 50px;
  text-align: center;
  left: 54px;
`;

export default Fixture1StudentNo;
