import axios from '../host';

// export const getMatchGroups = () => {
//   // return axios.get('/api/v2/match-group');
//   return axios.get('/api/v3/model/matchGroup?include=group');
// };

export const readMatchGroupsActive = () => {
  const promise = new Promise((resolve, reject) => {
    axios
      .get('/api/v2/match-group/active')
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        resolve(null);
      });
  });
  return promise;
};

export const readMatchGroupsHistory = () => {
  const promise = new Promise((resolve, reject) => {
    axios
      .get('/api/v2/match-group/history')
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        resolve(null);
      });
  });
  return promise;
};

export const readMatchGroup = (id) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .get(`/api/v2/match-group/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        resolve(null);
      });
  });
  return promise;
};

export const readMatchGroupStudentScore = (id) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .get(`/api/v2/match-group/${id}/student-scores`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        resolve(null);
      });
  });
  return promise;
};

export const readMatchGroupStudentScoreWithSort = (id) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .get(`/api/v2/match-group/${id}/student-scores?sort=true`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        resolve(null);
      });
  });
  return promise;
};

export const readMatches = (matchGroupId) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .get(`/api/v2/match-group/${matchGroupId}/matches`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        resolve(null);
      });
  });
  return promise;
};

export const editMatcheResults = (password, results) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .post(
        `/api/v2/match/results`,
        { password, results },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        // console.error(err);
        // resolve(null);
        reject(err.data ? err.data : err);
      });
  });
  return promise;
};

export const readHallOfFame = () => {
  const promise = new Promise((resolve, reject) => {
    axios
      .get(`/api/v2/match-group/hall-of-fame`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        resolve(null);
      });
  });
  return promise;
};
