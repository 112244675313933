import React from 'react';
import styled from 'styled-components';
import { DivClick } from '../styles/global';

function Fixture1Student(props) {
  const data = props.data ? props.data : {};
  const update = props.update;
  // const isWin = update.isWin ? false: data.matchResult === undefined || data.matchResult.isWin === null? null: !data.matchResult.isWin;
  let isWin = null;
  if (update) {
    isWin = update.isWin;
  } else {
    isWin = data.matchResult? data.matchResult.isWin: null;
  }
  // console.debug('Fixture1Student', data);
  // console.debug('Fixture1Student', isWin);
  return (
    <Container onClick={() => props.triggleResult()}{...props}>
      {data.isTeacher ? (
        <Teacher
          src={require('../assets/images/fixture1_teacher.png').default}
        ></Teacher>
      ) : (
        <Student
          src={require('../assets/images/fixture1_student.png').default}
        ></Student>
      )}
      {data.image ? (
        <StudentImage src={data.image}></StudentImage>
      ) : (
        <StudentImage
          src={require('../assets/images/student_image.jpg').default}
        ></StudentImage>
      )}
      {isWin === true ? (
        <FilterLose
          src={require('../assets/images/fixture1_filter_lose.png').default}
        ></FilterLose>
      ) : (
        ''
      )}
      {isWin === false ? (
        <FilterWin
          src={require('../assets/images/fixture1_filter_win.png').default}
        ></FilterWin>
      ) : (
        ''
      )}
      <StudentName>{data.name}</StudentName>
    </Container>
  );
}

const Container = styled(DivClick)`
  position: relative;
  display: flex;
`;

const Teacher = styled.img`
  top: 9px;
  left: 8px;
  width: 164px;
  height: 164px;
  position: absolute;
  object-fit: contain;
`;

const StudentImage = styled.img`
  top: 21px;
  left: 18px;
  width: 144px;
  height: 144px;
  position: absolute;
  object-fit: contain;
  border-radius: 50%;
`;

const Student = styled.img`
  top: 9px;
  left: 6px;
  width: 168px;
  height: 168px;
  position: absolute;
  object-fit: contain;
`;

const FilterLose = styled.img`
  top: 21px;
  left: 18px;
  width: 145px;
  height: 145px;
  position: absolute;
  object-fit: contain;
`;

const FilterWin = styled.img`
  top: 21px;
  left: 18px;
  width: 145px;
  height: 145px;
  position: absolute;
  object-fit: contain;
`;

const StudentName = styled.span`
  font-family: PSLxText;
  top: 139px;
  left: 0px;
  position: absolute;
  font-style: normal;
  font-weight: 700;
  color: white;
  height: 54px;
  width: 168px;
  font-size: 40px;
  text-align: center;
  -webkit-text-stroke: 1px black;
`;

export default Fixture1Student;
