import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

export const saveSuccess = () => {
  return MySwal.fire({
    title: 'Saved successfully',
    icon: 'success',
  });
};

export const deleteSuccess = () => {
  return MySwal.fire({
    title: 'Delete successfully',
    icon: 'success',
  });
};

export const confirmDelete = () => {
  return MySwal.fire({
    title: 'Delete Item',
    text: 'Are you sure to delete?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Ok',
    cancelButtonColor: '#d33',
    cancelButtonText: 'Cancel',
  });
};

export const errorMessage = (error) => {
  return MySwal.fire({ title: error, icon: 'error' });
  // if (error.length) {
  //   const msgString = error.map(v => v.msg);
  //   MySwal.fire({ title: msgString.join('\n'), icon: 'error' });
  // } else if (error.msg) {
  //   MySwal.fire({ title: error.msg, icon: 'error' });
  // }
};

export const confirmMove = () => {
  return MySwal.fire({
    title: 'You have modified data',
    text: 'Your modified data will be lost. Are you sure to change page?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Ok',
    cancelButtonColor: '#d33',
    cancelButtonText: 'Cancel',
  });
};
